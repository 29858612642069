import React from 'react';
import HeroTemplate from "../Components/HeroTemplate";
import ProductGrid from '../Components/ProductGrid';
import '../App.css';

const HostingTemplate = ({ background, fallingShapes, title, subtitle, buttons, products, introText }) => {
    return (
        <div className='App'>
            <HeroTemplate
                background={background}
                fallingShapes={fallingShapes}
                title={title}
                subtitle={subtitle}
                buttons={buttons}
            />
            <div className='intro-text'>
                <p className='text-primary' style={{
                    color: 'var(--text-primary)',
                    fontSize: '1.5rem',
                    maxWidth: '800px',
                    margin: 'auto',
                    textAlign: 'center',
                    padding: '1rem',
                }}>
                    {introText}
                </p>
            </div>
            <ProductGrid products={products} />
        </div>
    );
};

export default HostingTemplate;
