import rust_background from '../Assets/rust-background.jpg';
import HostingTemplate from '../Components/HostingTemplate';
import '../App.css';

const RustHosting = () => {
    const fallingShapes = [
        <img src="https://wiki.rustclash.com/img/items180/pistol.revolver.png" alt="Revolver" style={{width: '50px',height: '50px'}} />,
        <img src="https://wiki.rustclash.com/img/items180/pistol.python.png" alt="Python" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/smg.thompson.png" alt="Thompson" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/smg.mp5.png" alt="MP5" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/rifle.ak.png" alt="AK47" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/lmg.m249.png" alt="M249" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/skins/324/20001.png" alt="big-grin" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/skins/324/20002.png" alt="big-grin" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/pistol.revolver.png" alt="Revolver" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/pistol.python.png" alt="Python" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/smg.thompson.png" alt="Thompson" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/smg.mp5.png" alt="MP5" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/rifle.ak.png" alt="AK47" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/items180/lmg.m249.png" alt="M249" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/skins/324/20001.png" alt="big-grin" style={{ width: '50px', height: '50px' }} />,
        <img src="https://wiki.rustclash.com/img/skins/324/20002.png" alt="big-grin" style={{ width: '50px', height: '50px' }} />,

    ];
    const title = 'Rust Hosting';
    const background = { image: `url(${rust_background})` };
    const subtitle = 'Top-Notch Rust Game Hosting for the Best Multiplayer Experience';
    const buttons = [{ text: 'Start Your Survival Story!', href: '#products', type: 'filled' }];
    const introText = 'Get your Rust server up and running with our dedicated hosting. Enjoy high performance, low latency, and seamless gaming experience.';

    const products = [
        {
            name: 'Revolver',
            price: '9.50',
            type: 'rust-hosting',
            image: 'https://wiki.rustclash.com/img/items180/pistol.revolver.png',
            specs: [
                { cpu: '75% CPU' },
                { ram: '8GB RAM' },
                { storage: '10GB NVMe SSD' },
                { database: '1 MySQL Database' },
                { backup: '1 Backup' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/rust-hosting/revolver',
        },
        {
            name: 'Python',
            price: '12.50',
            type: 'rust-hosting',
            image: 'https://wiki.rustclash.com/img/items180/pistol.python.png',
            specs: [
                { cpu: '100% CPU' },
                { ram: '10GB RAM' },
                { storage: '20GB NVMe SSD' },
                { database: '1 MySQL Database' },
                { backup: '1 Backup' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/rust-hosting/python',
        },
        {
            name: 'Thompson',
            price: '15.50',
            type: 'rust-hosting',
            image: 'https://wiki.rustclash.com/img/items180/smg.thompson.png',
            specs: [
                { cpu: '125% CPU' },
                { ram: '12GB RAM' },
                { storage: '30GB NVMe SSD' },
                { database: '2 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/rust-hosting/thompson',
        },
        {
            name: 'MP5',
            best_seller: true,
            price: '23.50',
            type: 'rust-hosting',
            image: 'https://wiki.rustclash.com/img/items180/smg.mp5.png',
            specs: [
                { cpu: '150% CPU' },
                { ram: '16GB RAM' },
                { storage: '40GB NVMe SSD' },
                { database: '2 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/rust-hosting/mp5',
        },
        {
            name: 'AK47',
            price: '29.50',
            type: 'rust-hosting',
            image: 'https://wiki.rustclash.com/img/items180/rifle.ak.png',
            specs: [
                { cpu: '200% CPU' },
                { ram: '20GB RAM' },
                { storage: '50GB NVMe SSD' },
                { database: '2 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/rust-hosting/ak47',
        },
        {
            name: 'M249',
            price: '35.50',
            type: 'rust-hosting',
            image: 'https://wiki.rustclash.com/img/items180/lmg.m249.png',
            specs: [
                { cpu: '250% CPU' },
                { ram: '24GB RAM' },
                { storage: '50GB NVMe SSD' },
                { database: '2 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/rust-hosting/m249',
        }
    ];


    return (
        <HostingTemplate
            background={background}
            fallingShapes={fallingShapes}
            title={title}
            subtitle={subtitle}
            buttons={buttons}
            products={products}
            introText={introText}
        />
    );
};

export default RustHosting;
