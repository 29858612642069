import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Testimonials.css';

const Testemonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="testimonial-container">
            <div className="testimonial-section">
                <div className="testimonial-text">
                    <h1>What Our Customers Say</h1>
                    <h4>Read what our customers have to say about our service</h4>
                </div>
                <Slider className="Slider" {...settings}>
                    <div className="testimonial-card">

                        <div className="testimonial-avatar">
                            <img
                                src="https://scarcehost.uk/plugins/blesta_grape/assets/uploads/1/neovitalism-review.png"
                                alt="Neovitalism"/>
                            <div className="avatar-info">
                                <h5>Neovitalism</h5>
                                <span>Good Support</span>
                            </div>
                            <div className="testimonial-content">
                                <p>Support was simple, quick, and helpful.</p>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-card">

                        <div className="testimonial-avatar">
                            <img src="https://scarcehost.uk/plugins/blesta_grape/assets/uploads/1/abdul-review.png"
                                 alt="Abdul Khan"/>
                            <div className="avatar-info">
                                <h5>Abdul Khan</h5>
                                <span>ScarceHost is Amazing!!!!!!!</span>
                            </div>
                            <div className="testimonial-content">
                                <p>Great host, everything is awesome. I can run my Minecraft server for free and my
                                    Discord bot. We also started with 100 free credits which will last for about a month
                                    of hosting if you don't want the free plan. It's pretty great you should check it
                                    out.</p>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-card">

                        <div className="testimonial-avatar">
                            <img src="https://scarcehost.uk/plugins/blesta_grape/assets/uploads/1/brian-review.png"
                                 alt="Brian Mitchell"/>
                            <div className="avatar-info">
                                <h5>Brian Mitchell</h5>
                                <span>Helpful services</span>
                            </div>
                            <div className="testimonial-content">
                                <p>I love this hosting company because of how the owners are involved within its
                                    community.
                                    Not many hosts nowadays are; it just shows how much the staff team and its owners
                                    are
                                    willing to support their host. I feel like that's the best thing possible to run a
                                    company.</p>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-card">

                        <div className="testimonial-avatar">
                            <img src="https://scarcehost.uk/plugins/blesta_grape/assets/uploads/1/yazan-review.png"
                                 alt="Yazan Hamarsheh"/>
                            <div className="avatar-info">
                                <h5>Yazan Hamarsheh</h5>
                                <span>Personal Experience</span>
                            </div>
                            <div className="testimonial-content">
                                <p>Hi! ScarceHost is a very good service, I highly endorse them. The prices are great,
                                    as
                                    well as the services themselves. They respond quickly to support tickets while
                                    providing
                                    great support.</p>
                            </div>
                        </div>
                    </div>

                    <div className="testimonial-card">

                        <div className="testimonial-avatar">
                            <img src="https://scarcehost.uk/plugins/blesta_grape/assets/uploads/1/xiao-review.png"
                                 alt="Xiao Ming"/>
                            <div className="avatar-info">
                                <h5>Xiao Ming</h5>
                                <span>Outstanding host company that I ever SEEN</span>
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <p>I recently had the pleasure of utilizing ScarceHost's free hosting service for my
                                Minecraft server, and I must say, it has exceeded all my expectations. ScarceHost has
                                proven to be an exceptional hosting provider, offering a remarkable combination of
                                reliability, features, and exceptional customer support...</p>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Testemonial;
