import React from "react";

const Navbar = () => {
    return (
        <div>
            <nav className={'navbar'}>
                <div className={"logo-container"}>
                    <img className={'navbar-logo'} src={"/logo-color.svg"} alt="logo"/>
                    <img className={'navbar-logo-text'} src={"/logotype-color.svg"} alt="logo"/>
                </div>

                <div className="links">
                    <a href="/">Home</a>
                    <a href="/create">About us</a>
                    <a href="/create">Affiliates</a>
                    <div className={'dropdown'}>
                        <a href="/create">Support</a>
                        <div className={'dropdown-content'}>
                            <a href="https://scarcehost.uk/plugin/support_manager/client_tickets/">Tickets</a>
                            <a href="https://scarcehost.uk/plugin/support_manager/knowledgebase/">Knowledge Base</a>
                            <a href="https://discord.gg/92Zfh2bf2V">Discord</a>
                        </div>
                    </div>
                </div>
            </nav>
            <div className={'sub-navbar'}>
                <a href={'/minecraft'}>Minecraft</a>
                <a href={'/rust'}>Rust</a>
                <a href={'/vps'}>VPS</a>
                <a href={'/website'}>Website</a>
                <a href={'/bot'}>Bot</a>
                <a href={'/database'}>Database</a>
                <a href={'https://billing.scarcehost.uk/login'}>Free Hosting</a>
            </div>
        </div>

    );
}

export default Navbar;
