import React from 'react';
import '../App.css';

const HomeHero = () => {
    const getRandomPosition = () => {
        return {
            left: `${Math.random() * 100}vw`,
            animationDelay: `${Math.random() * 10}s`,
            animationDuration: `${Math.random() * 10 + 10}s`,
        };
    };

    return (
        <div className="home-hero">
            <div className="snow-container">
                {[...Array(50)].map((_, i) => (
                    <div
                        key={i}
                        className="snowflake"
                        style={getRandomPosition()}
                    >
                        ❄
                    </div>
                ))}
            </div>
            <div className="home-hero-section">
                <div className="home-hero-text">
                    <h1>Perfect Hosting<br/>Service For You</h1>
                    <h4>Get Started from just <span className="blue">£1.00</span>/month<br/> and join over 10,750 users</h4>
                    <div className="home-btn-container">
                        <a className="btn-filled" href="#featured">Get Started</a>
                        <a className="btn-outline" href="#">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeHero;
