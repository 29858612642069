
// Import the CPU, Memory, Disk, Database, and Backup icons
import { faMicrochip, faMemory, faHdd, faDatabase, faSave } from '@fortawesome/free-solid-svg-icons';
import { faGlobe, faShieldBlank, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductCard = ({ product }) => {
    console.log(product);


    
   if (!product.best_seller && product.type === 'mc-hosting') {
       return (
           <div className="product-card">
               <h2>{product.name}</h2>
               <img className='product-img' src={product.image} alt={product.name} />
               <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
               <div className='line'></div>
               <div className='product-grid-features'>
                   <span>
                       <FontAwesomeIcon icon={faMicrochip} size='1x' color='var(--text-secondary)' /> {product.specs[0].cpu}
                   </span>
                   <span>
                       <FontAwesomeIcon icon={faMemory} size='1x' color='var(--text-secondary)' /> {product.specs[1].ram}
                   </span>
                   <span>
                       <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs[2].storage}
                   </span>
                   <span>
                       <FontAwesomeIcon icon={faDatabase} size='1x' color='var(--text-secondary)' /> {product.specs[3].database}
                   </span>
                   <span>
                       <FontAwesomeIcon icon={faSave} size='1x' color='var(--text-secondary)' /> {product.specs[4].backup}
                   </span>
               </div>
               <div className='product-grid-btn'>
                   <a href={product.buy_now} className='product-button'>Buy Now</a>
               </div>
           </div>


       );
   } else if (product.best_seller && product.type === 'mc-hosting') {
         return (
              <div className="product-card best-seller">
                <div className='best-seller-banner'>
                    <p>Best Seller</p>
                </div>
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                     <span>
                          <FontAwesomeIcon icon={faMicrochip} size='1x' color='var(--text-secondary)' /> {product.specs[0].cpu}
                     </span>
                     <span>
                          <FontAwesomeIcon icon={faMemory} size='1x' color='var(--text-secondary)' /> {product.specs[1].ram}
                     </span>
                     <span>
                          <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs[2].storage}
                     </span>
                     <span>
                          <FontAwesomeIcon icon={faDatabase} size='1x' color='var(--text-secondary)' /> {product.specs[3].database}
                     </span>
                     <span>
                          <FontAwesomeIcon icon={faSave} size='1x' color='var(--text-secondary)' /> {product.specs[4].backup}
                     </span>
                </div>
                <div className='product-grid-btn'>
                     <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
              </div>
            );
   } else if (product.type === 'web-hosting' && product.best_seller) {
        return (
            <div className="product-card best-seller">
                <div className='best-seller-banner'>
                    <p>Best Seller</p>
                </div>
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                    <span>
                        <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs[0].disk}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faGlobe} size='1x' color='var(--text-secondary)' /> {product.specs[1].domains}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faShieldBlank} size='1x' color='var(--text-secondary)' /> {product.specs[2].ssl}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faDatabase} size='1x' color='var(--text-secondary)' /> {product.specs[3].databases}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faNetworkWired} size='1x' color='var(--text-secondary)' /> {product.specs[4].network}
                    </span>
                </div>
                <div className='product-grid-btn'>
                    <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
            </div>
        );
    } else if (product.type === 'web-hosting' && !product.best_seller) {
        return (
            <div className="product-card">
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                    <span>
                        <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs[0].disk}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faGlobe} size='1x' color='var(--text-secondary)' /> {product.specs[1].domains}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faShieldBlank} size='1x' color='var(--text-secondary)' /> {product.specs[2].ssl}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faDatabase} size='1x' color='var(--text-secondary)' /> {product.specs[3].databases}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faNetworkWired} size='1x' color='var(--text-secondary)' /> {product.specs[4].network}
                    </span>
                </div>
                <div className='product-grid-btn'>
                    <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
            </div>
        );
    } else if (product.type === 'vps-hosting' && product.best_seller) {
        return (
            <div className="product-card best-seller">
                <div className='best-seller-banner'>
                    <p>Best Seller</p>
                </div>
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                    <span>
                        <FontAwesomeIcon icon={faMicrochip} size='1x' color='var(--text-secondary)' /> {product.specs.cpu}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faMemory} size='1x' color='var(--text-secondary)' /> {product.specs.memory}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs.disk}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faNetworkWired} size='1x' color='var(--text-secondary)' /> {product.specs.network}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faShieldBlank} size='1x' color='var(--text-secondary)' /> {product.specs.ddos_protection}
                    </span>
                </div>
                <div className='product-grid-btn'>
                    <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
            </div>
        );
    } else if (product.type === 'vps-hosting' && !product.best_seller) {
        return (
            <div className="product-card">
                
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                    <span>
                        <FontAwesomeIcon icon={faMicrochip} size='1x' color='var(--text-secondary)' /> {product.specs.cpu}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faMemory} size='1x' color='var(--text-secondary)' /> {product.specs.memory}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs.disk}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faNetworkWired} size='1x' color='var(--text-secondary)' /> {product.specs.network}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faShieldBlank} size='1x' color='var(--text-secondary)' /> {product.specs.ddos_protection}
                    </span>
                </div>
                <div className='product-grid-btn'>
                    <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
            </div>
        );
    } else if (product.type === 'rust-hosting' && product.best_seller) {
        return (
            <div className="product-card best-seller">
                <div className='best-seller-banner'>
                    <p>Best Seller</p>
                </div>
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                    <span>
                        <FontAwesomeIcon icon={faMicrochip} size='1x' color='var(--text-secondary)' /> {product.specs[0].cpu}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faMemory} size='1x' color='var(--text-secondary)' /> {product.specs[1].ram}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs[2].storage}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faDatabase} size='1x' color='var(--text-secondary)' /> {product.specs[3].database}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faSave} size='1x' color='var(--text-secondary)' /> {product.specs[4].backup}
                    </span>
                </div>
                <div className='product-grid-btn'>
                    <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
            </div>
        );
    } else if (product.type === 'rust-hosting' && !product.best_seller) {
        return (
            <div className="product-card">
                <h2>{product.name}</h2>
                <img className='product-img' src={product.image} alt={product.name} />
                <p>£{product.price}<span className={'txt-secondary'}>/month</span></p>
                <div className='line'></div>
                <div className='product-grid-features'>
                    <span>
                        <FontAwesomeIcon icon={faMicrochip} size='1x' color='var(--text-secondary)' /> {product.specs[0].cpu}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faMemory} size='1x' color='var(--text-secondary)' /> {product.specs[1].ram}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faHdd} size='1x' color='var(--text-secondary)' /> {product.specs[2].storage}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faDatabase} size='1x' color='var(--text-secondary)' /> {product.specs[3].database}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faSave} size='1x' color='var(--text-secondary)' /> {product.specs[4].backup}
                    </span>
                </div>
                <div className='product-grid-btn'>
                    <a href={product.buy_now} className='product-button'>Buy Now</a>
                </div>
            </div>
        );
    }


}



const ProductGrid = ({ products }) => {
    return (
        <div className="product-grid" id={'products'}>
            {products.map((product) => (
                <ProductCard key={product.id} product={product} />
            ))}
        </div>
    );
}

export default ProductGrid;