import React from 'react';
import '../App.css';

const HomeHero = ({ background, fallingShapes, title, subtitle, buttons }) => {
    const getRandomPosition = () => {
        return {
            left: `${Math.random() * 100}vw`,
            animationDelay: `${Math.random() * 10}s`,
            animationDuration: `${Math.random() * 10 + 10}s`,
        };
    };

    return (
        <div className="home-hero"
            style={{
                backgroundImage: background.image,
                backgroundSize: 'cover',
            }}>

            <div className='home-hero-overlay'
                style={{
                    backgroundColor: `rgba(0, 0, 0, 1)`,
                    backgroundSize: 'cover',
                    zIndex: 88,
                }}
            ></div>
            <div className="snow-container">
                {fallingShapes.map((shape, i) => (
                    <div
                        key={i}
                        className="snowflake"
                        style={getRandomPosition()}
                    >
                        {shape}
                    </div>
                ))}
            </div>
            <div className="home-hero-section"

            >
                <div className="home-hero-text">
                    <h1 className='txt-primary'
                        style={{
                            color: 'var(--text-secondary)',
                        }}
                    >{title}</h1>
                    <h4 dangerouslySetInnerHTML={{ __html: subtitle }} style={{
                        maxWidth: '50rem',
                        textAlign: 'center',
                    }} />
                    <div className="home-btn-container">
                        {buttons.map((button, i) => (
                            <a
                                key={i}
                                className={button.type === 'filled' ? 'btn-filled' : 'btn-outline'}
                                href={button.href}
                            >
                                {button.text}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeHero;
