import mc_background from '../Assets/mc-background.webp';
import HeroTemplate from "../Components/HeroTemplate";
import '../App.css'
import ProductGrid from '../Components/ProductGrid';
const Minecraft = () => {
    // Drop swords from the sky
    const fallingShapes = [
        <img className='falling-img' src="https://static.wikia.nocookie.net/minecraft_gamepedia/images/4/44/Diamond_Sword_JE3_BE3.png" alt="🗡️"  style={{width: '50px', }}/> ,
        <img className='falling-img' src="https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp" alt="🗡️"  style={{width: '50px', }}/>,
        <img className='falling-img' src="https://static.wikia.nocookie.net/minecraft_gamepedia/images/4/44/Diamond_Sword_JE3_BE3.png" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.wikia.nocookie.net/minecraft_gamepedia/images/4/44/Diamond_Sword_JE3_BE3.png" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.wikia.nocookie.net/minecraft_gamepedia/images/4/44/Diamond_Sword_JE3_BE3.png" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.wikia.nocookie.net/minecraft_gamepedia/images/4/44/Diamond_Sword_JE3_BE3.png" alt="🗡️" style={{ width: '50px', }} />,
        <img className='falling-img' src="https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp" alt="🗡️" style={{ width: '50px', }} />,
    ]; 
        
    
    const title = 'Minecraft Hosting';
    const background = { image: 'url('+ mc_background+ ')'};
    const subtitle = 'Experience The Best Minecraft Hosting At The Best Price';
    const buttons = [
        { text: 'Build Your Kingdom', href: '#products', type: 'filled' },
    ];

    const products = [
        {
            name: 'Beginner',
            best_seller: false,
            type: 'mc-hosting',
            price: '2.00',
            image: 'https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp',
            specs : [
                { cpu: '1 CPU' },
                { ram: '2GB RAM' },
                { storage: '3GB Disk' },
                { database: '1 MySQL Database' },
                { backup: '1 Backup' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/minecraft-servers/beginner',
        }, {
            name: 'Explorer',
            best_seller: false,
            type: 'mc-hosting',
            price: '4.00',
            image: 'https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp',
            specs: [
                { cpu: '1 CPU' },
                { ram: '4GB RAM' },
                { storage: '5GB Disk' },
                { database: '1 MySQL Database' },
                { backup: '1 Backup' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/minecraft-servers/',
        }, {
            name: 'Adventurer',
            best_seller: true,
            type: 'mc-hosting',
            price: '6.00',
            image: 'https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp',
            specs: [
                { cpu: '2 CPU' },
                { ram: '6GB RAM' },
                { storage: '8GB Disk' },
                { database: '2 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/minecraft-servers/adventurer',
        }, {
            name: 'Master',
            best_seller: false,
            type: 'mc-hosting',
            price: '8.00',
            image: 'https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp',
            specs: [
                { cpu: '2 CPU' },
                { ram: '8GB RAM' },
                { storage: '10GB Disk' },
                { database: '2 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/minecraft-servers/master',
        }, {
            name: 'Champion',
            best_seller: false,
            type: 'mc-hosting',
            price: '16.00',
            image: 'https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp',
            specs: [
                { cpu: '4 CPU' },
                { ram: '16GB RAM' },
                { storage: '20GB Disk' },
                { database: '3 MySQL Databases' },
                { backup: '3 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/minecraft-servers/champion',
        }, {
            name: 'Legend',
            price: '24.00',
            best_seller: false,
            type: 'mc-hosting',
            image: 'https://static.planetminecraft.com/files/image/minecraft/texture-pack/2022/576/16059999-pack_l.webp',
            specs: [
                { cpu: '4 CPU' },
                { ram: '24GB RAM' },
                { storage: '25GB Disk' },
                { database: '4 MySQL Databases' },
                { backup: '4 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/minecraft-servers/legend',
        },
    ];
    
    return (
        <div className='App'>
            <HeroTemplate background={background} fallingShapes={fallingShapes} title={title} subtitle={subtitle} buttons={buttons} />
            <div className='intro-text'>
                <p className='text-primary' style={{
                    color: 'var(--text-primary)',
                    fontSize: '1.5rem',
                    
                    maxWidth: '800px',
                    margin: 'auto',
                    textAlign: 'center',
                    padding: '1rem',
                }}>
                    Introducing our Minecraft Hosting packages - your gateway to endless adventures in the blocky realm. Tailored for gamers and creators alike, our hosting solutions offer exceptional performance, flexibility, and reliability. Unleash your creativity, build, and explore with peace of mind, knowing your Minecraft world is in the hands of experts.
                    </p>
            </div>
            <ProductGrid products={products} />
        </div>
        
    );
    }

export default Minecraft;