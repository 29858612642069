import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';

import Home from './Pages/Home';
import Minecraft from './Pages/Minecraft';
import RustHosting from './Pages/Rust';
import WebHosting from './Pages/WebHosting';
import FoF from './Pages/FoF';
import VPS from './Pages/VPS';
const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="*" element={<FoF />} />
                <Route path="/" element={<Home />} />
                <Route path="/minecraft" element={<Minecraft/>} />
                <Route path="/rust" element={<RustHosting />} />
                <Route path="/website" element={<WebHosting />} />
                <Route path="/vps" element={<VPS />} />
            </Routes>
        </Router>
    );
}

export default App;
