const FoF = () => {
    return (
        <div className="App">
            <div className="fof-container" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}>
                <span style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <img src="https://media.tenor.com/tDau9a7i5hwAAAAi/alert-siren.gif" alt="404" style={{ width: '50px' }} />
                    <h1 className="txt-primary">Hmmm... Thats not good...</h1>
                    <img src="https://media.tenor.com/tDau9a7i5hwAAAAi/alert-siren.gif" alt="404"  style={{width: '50px'}}/>

                </span>
                <h2 className="txt-secondary">Either You're Lost or You Broke Something</h2>
                <img src="https://i.giphy.com/XYvCGLq4Xd2XT5typ7.webp" alt="404" />
                <a className="btn-filled" href="/" style={{
                    textDecoration: 'none',
                    color: 'white',
                    padding: '10px 20px',
                }}>RUN! RUN!</a>
                </div>
        </div>
    );
    }

export default FoF;